<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="dialog"
    persistent
  >
    <template v-slot:default="">
      <v-card>
        <v-toolbar color="primary" dark
          >{{ direccion ? "Editar" : "Nueva" }} direccion</v-toolbar
        >
        <v-card-text>
          <v-text-field
            label="Descripcion"
            v-model="datos.descripcion"
            required
            @input="$v.datos.descripcion.$touch()"
            @blur="$v.datos.descripcion.$touch()"
            :error="$v.datos.descripcion.$error"
          ></v-text-field>
          <v-autocomplete
            label="Provincia"
            v-model="datos.provincia"
            :loading="loadCiudades"
            :items="provincias"
            item-text="nombre"
            item-value="_id"
            no-data-text="No se encontraron datos"
            @change="obtenerCiudades"
            return-object
            required
            @input="$v.datos.provincia.$touch()"
            @blur="$v.datos.provincia.$touch()"
            :error="$v.datos.provincia.$error"
          ></v-autocomplete>
          <v-autocomplete
            label="Ciudad"
            v-model="datos.ciudad"
            :loading="loadCiudades"
            :items="ciudades"
            item-text="nombre"
            item-value="_id"
            no-data-text="Seleccione una provincia"
            return-object
            required
            @input="$v.datos.ciudad.$touch()"
            @blur="$v.datos.ciudad.$touch()"
            :error="$v.datos.ciudad.$error"
          ></v-autocomplete>
          <v-text-field
            label="Codigo Postal"
            v-model="datos.cp"
            @input="$v.datos.cp.$touch()"
            @blur="$v.datos.cp.$touch()"
            :error="$v.datos.cp.$error"
          ></v-text-field>
          <v-text-field
            label="Direccion"
            v-model="datos.direccion_1"
            required
            @input="$v.datos.direccion_1.$touch()"
            @blur="$v.datos.direccion_1.$touch()"
            :error="$v.datos.direccion_1.$error"
          ></v-text-field>
          <v-text-field
            label="Referencias"
            v-model="datos.direccion_2"
          ></v-text-field>
          <v-btn
            block
            color="green"
            dark
            @click="agregarDireccion"
            :disabled="$v.datos.$invalid"
            ><v-icon>mdi-check</v-icon></v-btn
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text dark color="red" @click="cerrarDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "Cuenta_DialogAgregarDireccion",
  props: {
    llamar: { type: Boolean, default: false },
    direccion: { type: Object },
  },
  validations: {
    datos: {
      descripcion: {
        required,
        minLength: minLength(3),
      },
      provincia: { required },
      ciudad: { required },
      direccion_1: { required, minLength: minLength(6) },
      cp: { required, minLength: minLength(4) },
    },
  },
  data: () => ({
    dialog: false,
    loadCiudades: false,
    provincias: [],
    ciudades: [],
    datos: {
      descripcion: "",
      direccion_1: "",
      direccion_2: "",
      provincia: {
        _id: "",
        nombre: "",
      },
      ciudad: {
        _id: "",
        nombre: "",
      },
      cp: "",
    },
  }),
  watch: {
    llamar: function() {
      this.dialog = this.llamar;
      this.$nextTick(() => {
        this.$v.datos.$reset();
      });
    },
    direccion: function() {
      this.ciudades = [];
      this.datos = {
        descripcion:
          this.direccion && this.direccion.descripcion
            ? this.direccion.descripcion
            : "",
        direccion_1:
          this.direccion && this.direccion.direccion_1
            ? this.direccion.direccion_1
            : "",
        direccion_2:
          this.direccion && this.direccion.direccion_2
            ? this.direccion.direccion_2
            : "",
        provincia: {
          _id:
            this.direccion && this.direccion.provincia
              ? this.direccion.provincia._id
              : "",
          nombre:
            this.direccion && this.direccion.provincia
              ? this.direccion.provincia.nombre
              : "",
        },
        ciudad: {
          _id:
            this.direccion && this.direccion.ciudad
              ? this.direccion.ciudad._id
              : "",
          nombre:
            this.direccion && this.direccion.ciudad
              ? this.direccion.ciudad.nombre
              : "",
        },
        cp: this.direccion && this.direccion.cp ? this.direccion.cp : "",
        i: this.direccion && this.direccion.i > -1 ? this.direccion.i : null,
      };
      if (this.direccion) this.obtenerCiudades();
    },
  },
  methods: {
    async obtenerCiudades() {
      this.loadCiudades = true;
      try {
        let consulta = await this.axios.post("/registro/buscarCiudad", {
          provincia: this.datos.provincia._id,
        });
        if (consulta.data.provincias) {
          this.provincias = consulta.data.provincias;
        } else if (consulta.data.ciudades) {
          this.ciudades = consulta.data.ciudades;
        }
      } catch (error) {}
      this.loadCiudades = false;
    },
    cerrarDialog() {
      if (this.$route.params.nuevaDireccion)
        this.$router.push(this.$route.params.volver);
      this.$emit("cerrar", false);
    },
    async agregarDireccion() {
      this.$v.datos.$touch();
      if (!this.$v.datos.$invalid) {
        if (this.$route.params.nuevaDireccion) {
          try {
            await this.axios.post('/cuenta/guardarDireccion', { direccion: this.datos })
            this.$route.params.volver.params.nuevaDireccion = true
            this.$router.push({
            ...this.$route.params.volver
          });
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$emit("cerrar", this.datos);
          this.datos = {};
        }
      }
    },
  },
  created() {
    this.obtenerCiudades();
  },
};
</script>
