<template>
  <v-container fluid>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <v-row wrap align-center>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-overlay v-if="!usuario" absolute>
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <div v-else>
              <v-tabs center-active v-model="tab">
                <v-tab>Pedidos</v-tab>
                <v-tab>Favoritos</v-tab>
                <v-tab>Mis datos</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item><Pedidos /></v-tab-item>
                <v-tab-item>Favoritos</v-tab-item>
                <v-tab-item><DatosPersonales :datos="usuario"/></v-tab-item>
              </v-tabs-items>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatosPersonales from "../components/Cuenta/DatosPersonales";
import Pedidos from "../components/Cuenta/Pedidos";
export default {
  name: "Cuenta",
  metaInfo: {
      title: 'Ver mis pedidos',
    },
  components: { DatosPersonales, Pedidos },
  data: () => ({
    tab: 0,
    usuario: null,
    breadcrumbs: [
      {
        text: "Ir a Tienda",
        disabled: false,
        to: "/tienda",
      },
    ],
  }),
  methods: {
    async obtenerDatosUsuario() {
      try {
        const usuario = await this.axios.get("/datosUsuario");
        this.usuario = usuario.data;
        this.usuario.fechaNacimiento = this.$moment.utc(this.usuario.fechaNacimiento).format("Y-MM-DD")
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.obtenerDatosUsuario();
  },
};
</script>
