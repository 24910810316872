<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Datos personales
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            :label="datos.esEmpresa ? 'Razon Social' : 'Apellido y Nombre'"
            v-model="usuario.nombre"
            required
            @input="$v.usuario.nombre.$touch()"
            @blur="$v.usuario.nombre.$touch()"
            :error="$v.usuario.nombre.$invalid"
          ></v-text-field>
          <v-menu
            ref="menu"
            v-model="menuFechaNac"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaNacimiento"
                :label="
                  'Fecha de ' +
                    (usuario.esEmpresa ? 'Conformacion' : 'Nacimiento')
                "
                readonly
                v-bind="attrs"
                v-on="on"
                class="mt-n2"
                required
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="usuario.fechaNacimiento"
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
              @change="guardarFechaNac"
              @input="$v.usuario.fechaNacimiento.$touch()"
              @blur="$v.usuario.fechaNacimiento.$touch()"
              :error="$v.usuario.fechaNacimiento.$invalid"
            ></v-date-picker>
          </v-menu>
          <v-autocomplete
            :items="tipoResponsable"
            v-model="usuario.tipo_responsable"
            label="Tipo de Responsable Fiscal"
          ></v-autocomplete>
          <v-autocomplete
            :items="tipoDocumento"
            label="Tipo de Documento"
            v-model="usuario.documento.tipo"
            disabled
          ></v-autocomplete>
          <v-text-field
            label="N° de Documento"
            v-model="usuario.documento.numero"
            disabled
          ></v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Direcciones
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Descripcion
                  </th>
                  <th class="text-left">
                    Direccion
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in usuario.direcciones" :key="index">
                  <td>{{ item.descripcion }}</td>
                  <td>
                    {{ item.direccion_1 }}, {{ item.direccion_2 }},
                    {{ item.ciudad.nombre }}, {{ item.provincia.nombre }} CP
                    {{ item.cp }}
                  </td>
                  <td>
                    <v-icon @click="llamarDialogDireccion(index)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      @click="quitarDireccion(index)"
                      v-if="usuario.direcciones.length > 1"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn block @click="llamarDialogDireccion(-1)"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Medios de contacto
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Contacto
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in usuario.contactos" :key="index">
                  <td>
                    <v-icon
                      >mdi-{{
                        item.medio == "sms" ? "cellphone" : "at"
                      }}</v-icon
                    >
                    {{ item.dato }}
                  </td>
                  <td>
                    <v-chip class="ma-2" color="teal" text-color="white">
                      <v-avatar left>
                        <v-icon>mdi-checkbox-marked-circle</v-icon>
                      </v-avatar>
                      Verificado
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      block
      :color="!$v.usuario.$invalid ? 'green' : 'red'"
      dark
      class="mt-2 pa-2"
      @click="guardarDatos"
      :loading="loadingGuardarDatos"
      ><v-icon
        >mdi-{{ !$v.usuario.$invalid ? "check" : "close" }}</v-icon
      ></v-btn
    >
    <DialogDireccion
      :llamar="dialogDireccion"
      :direccion="direccionSeleccionada"
      @cerrar="cerrarDialogDireccion"
    />
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import DialogDireccion from "./DialogAgregarDireccion";
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  name: "Cuenta_DatosPersonales",
  components: { DialogDireccion },
  props: {
    datos: {
      type: Object,
    },
  },
  validations: {
    usuario: {
      nombre: {
        required,
        minLength: minLength(6),
      },
      fechaNacimiento: { required },
      direcciones: { required },
    },
  },
  data: () => ({
    panel: [0, 1, 2],
    dialogDireccion: false,
    direccionSeleccionada: null,
    menuFechaNac: false,
    usuario: {
      nombre: "",
      tipo_responsable: "",
      documento: {
        tipo: "",
        numero: "",
      },
      direcciones: [],
    },
    tipoResponsable: [
      "CONSUMIDOR FINAL",
      "MONOTRIBUTISTA",
      "RESPONSABLE INSCRIPTO",
    ],
    tipoDocumento: ["CUIT", "DNI", "PASAPORTE"],
    loadingGuardarDatos: false,
  }),
  created() {
    this.usuario = this.datos;
    this.$nextTick(() => {
      if (this.$route.params.nuevaDireccion) this.llamarDialogDireccion(-1);
    });
  },
  watch: {
    menuFechaNac(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    fechaNacimiento() {
      return this.usuario.fechaNacimiento
        ? this.$moment(this.usuario.fechaNacimiento).format("DD/MM/YYYY")
        : "";
    },
  },
  methods: {
    ...mapMutations(["abrirSnackbar"]),
    async guardarDatos() {
      this.$v.usuario.$touch();
      if (!this.$v.usuario.$invalid) {
        this.loadingGuardarDatos = true;
        try {
          await axios.post("/guardarDatosUsuario", this.usuario);
          this.abrirSnackbar({
            texto: `Se guardaron los datos.`,
            color: "green",
            timeout: 2000,
          });
        } catch (error) {
          this.abrirSnackbar({
            texto: `Hubo un error al guardar los datos, por favor reintente.`,
            color: "red",
            timeout: 2000,
          });
        }
        this.loadingGuardarDatos = false;
      }
    },
    guardarFechaNac(date) {
      this.$refs.menu.save(date);
    },
    llamarDialogDireccion(i) {
      if (i > -1) {
        this.direccionSeleccionada = {
          ...this.usuario.direcciones[i],
          i,
        };
      }
      this.dialogDireccion = true;
    },

    cerrarDialogDireccion(item) {
      if (item) {
        this.$nextTick(() => {
          item.i > -1
            ? this.usuario.direcciones.splice(item.i, 1, item)
            : this.usuario.direcciones.push(item);
        });
      }

      this.direccionSeleccionada = null;
      this.dialogDireccion = false;
    },
    quitarDireccion(i) {
      if (confirm("Confirma quitar esta direccion?"))
        this.usuario.direcciones.splice(i, 1);
    },
  },
};
</script>
