<template>
  <div>
    <v-overlay v-if="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-else>
      <v-data-table
      v-if="pedidos.length > 0"
      :headers="headers"
      :items="pedidos"
      class="elevation-1"
    >
      <template v-slot:item._id="{ item }">
        {{ item._id.slice(-5) }}
      </template>
      <template v-slot:item.envio="{ item }">
          <v-icon
            >mdi-{{ item.envio.icono }}</v-icon
          >
          {{ item.envio.texto }}
      </template>
      <template v-slot:item.total="{ item }">
        $ {{ item.total }}
      </template>
      <template v-slot:item.fechaAlta="{ item }">
        {{ $moment(item.fechaAlta).format("dddd DD [de] MMMM YYYY H:mm") }}
      </template>
      <template v-slot:item.estado="{ item }">
        <span :class="`${item.estado.color}--text`"
          ><v-icon :color="item.estado.color"
            >mdi-{{ item.estado.icono }}</v-icon
          >
          {{ item.estado.texto }}</span
        >
      </template>
      <template v-slot:item.accion="{ item }">
        <v-btn text @click="$router.push(`/pedido/${item._id}`)">{{item.estado.boton}} <v-icon>mdi-arrow-right</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-card v-else class="pa-4">
      <v-card-text>
        <h1>Aun no tenes pedidos</h1>
        <p class="pa-2">Elegi los productos que te interesen, llena el carrito y armá tu primer pedido</p>
        <v-btn x-large block color="green" dark @click="$router.push('/tienda')">VER PRODUCTOS</v-btn>
        <v-btn class="mt-2" text block @click="$router.push('/carrito')" v-if="$store.state.carrito.productos.length>0">Ir a mi carrito</v-btn>
      </v-card-text>
    </v-card>
    </div>

  </div>
</template>

<script>
export default {
  name: "Cuenta_Pedidos",
  data: () => ({
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "_id",
      },
      { text: "Fecha y Hora", value: "fechaAlta" },
      { text: "Envio", value: "envio" },
      { text: "Total", value: "total" },
      { text: "Estado", value: "estado" },
      { text: "", value: "accion" },
    ],
    pedidos: [],
    loading: true,
  }),
  methods: {
    async _obtenerPedidos() {
      this.loading = true;
      try {
        const pedidos = await this.axios.get("/obtenerPedidos");
        this.pedidos = pedidos.data;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    estadoPedidoIcono(estado) {
      switch (estado) {
        case "Pendiente de pago":
          return "cash-register";
      }
    },
  },
  created() {
    this._obtenerPedidos();
  },
};
</script>
